export const removeAuthLocalStorage = () => {
  localStorage.removeItem("BoosstLogin");
  localStorage.removeItem("updatedTenant");
  localStorage.removeItem("jwtToken");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("disableHJ");
  localStorage.removeItem("isAdmin");
};

export const getLocalStorageValue = (key) => {
  return localStorage.getItem(key)
    ? JSON.parse(localStorage.getItem(key))
    : null;
};

export const getAndRemoveLSItem = (key) => {
  let value = localStorage.getItem(key);
  localStorage.removeItem(key);
  return value;
};
