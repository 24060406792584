import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { CheckUser } from '../components/Auth/success';
import LoadingScreen from '../components/LoadingScreen';
import useAuth from '../hooks/useAuth';

const Register = React.lazy(() => import('../components/Auth/Register'));
const Onboarding = React.lazy(() => import('../components/Onboarding'));
const LayoutComponent = React.lazy(() => import('../components/Layout/Layout'));
const ProfitAndLossComponent = React.lazy(() => import('../components/ProfitAndLoss/P&L'));

function CustomRoutes() {
  const { loginStatus, loading } = useAuth();

  if (loading) return <LoadingScreen />;

  return (
    <React.Suspense fallback={<LoadingScreen />}>
      <Routes>
        {!!loginStatus && (
          <>
            <Route path="/marketplace" element={<Onboarding />} />
            <Route path="/home/*" element={<LayoutComponent />} />
            <Route path="/profitandloss" element={<ProfitAndLossComponent />} />
          </>
        )}
        <Route path="/" element={<Register />} />
        <Route path="/success" element={<CheckUser />} />
      </Routes>
    </React.Suspense>
  );
}

export default CustomRoutes;
