import * as BaseAction from "./baseService";

export function registerUser(id) {
  return BaseAction.BaseService.post(`/public/signup`, id);
}

export function checkUser(userId) {
  return BaseAction.BaseService.get(`/checkUser/${userId}`);
}

export function checkSyncStatus(tenantId) {
  return BaseAction.BaseService.get(`/checkSyncStatus/${tenantId}`);
}

export function getUser() {
  return BaseAction.BaseService.get(`/getUser`);
}

export function logout() {
  return BaseAction.BaseService.get(`/public/logout`);
}

export function getMarketPlaceList() {
  return BaseAction.BaseService.get(`/marketPlaceList`);
}

export function getMarketPlace(userId) {
  return BaseAction.BaseService.get(`/marketPlace/${userId}`);
}

export function updateMarketPlace(data) {
  return BaseAction.BaseService.put(`/marketPlace/${data.id}`, data);
}

export function updateProfile(data) {
  return BaseAction.BaseService.post(`/profile`, data, {
    headers: { "Content-type": "multipart/form-data" },
  });
}

export function getProfile(id) {
  return BaseAction.BaseService.get(`/profile/${id}`);
}

export function uploadCSV(data) {
  return BaseAction.BaseService.post(`/uploadCSV`, data);
}

export function getProducts(userId) {
  return BaseAction.BaseService.get(`/getProducts/${userId}`);
}

export function sellBusiness(tenantId) {
  return BaseAction.BaseService.get(`/sellBusiness/${tenantId}`);
}

export function dashboard(data) {
  return BaseAction.BaseService.post(`/dashboard`, data);
}

export function estimateCalculation(data) {
  return BaseAction.BaseService.post(`/estimate`, data);
}

export function getAllTenantList() {
  return BaseAction.BaseService.get(`/allTenant`);
}

export function getDashboradFromProfitAndLoss(data) {
  return BaseAction.BaseService.post(`/calculateValuation`, data);
}

export function getDateRangeFromProfitAndLoss(data) {
  return BaseAction.BaseService.post(`/get-latest-record-date`, data);
}