import React, { useEffect } from 'react';

import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ThemeProvider } from '@mui/material/styles';
import { AuthProvider } from './contexts/AuthContext';
import { hotjar } from 'react-hotjar';
import { HOTJAR_ID, HOTJAR_SV } from './constants';
import Routes from './routes';
import theme from './MuiTheme';
import './App.css';
import './assets/css/styles.css';

function App() {
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    let disableHJ = queryParams.get('disableHJ');
    localStorage.setItem('disableHJ', disableHJ);

    if (!disableHJ) {
      console.log('hotjar initialized');
      hotjar.initialize(HOTJAR_ID, HOTJAR_SV);
    }
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <ToastContainer />
            <Routes />
          </AuthProvider>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
