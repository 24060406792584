import React, { createContext, useEffect, useState } from "react";
import { hotjar } from "react-hotjar";
import { useLocation, useNavigate } from "react-router-dom";
import * as authService from "../services/authService";
import Swal from "sweetalert2";
import { getAndRemoveLSItem, removeAuthLocalStorage } from "../utils/common";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const locaBoosstLogin = localStorage.getItem("BoosstLogin");

  const [loginStatus, setLoginStatus] = useState(
    locaBoosstLogin && locaBoosstLogin !== "undefined"
      ? JSON.parse(locaBoosstLogin)
      : null
  );
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const onLoginStatusChange = (data) => {
    setLoginStatus(data);
  };

  const onSignOut = () => {
    authService.logout().then((res) => {
      removeAuthLocalStorage();

      setLoginStatus(null);
      if (window.hj) hotjar?.event("signed out");

      console.log("user logged out", res);
      window.open("/", "_self");
    });
  };

  const checkUser = (id, updatedTenant = false) => {
    console.log("checkUser called");
    authService.checkUser(id).then((res) => {
      if (res?.data?.data) {
        if (updatedTenant && res?.data?.data?.id)
          localStorage.setItem("updatedTenant", res.data.data.id);

        let data = JSON.stringify(res?.data?.data);
        localStorage.setItem("BoosstLogin", data);
        onLoginStatusChange(JSON.parse(data));
        setLoading(false);
      } else {
        navigate("/");
      }
    });
  };

  const getUser = (checkQP = false) => {
    console.log("getUser called");
    if (checkQP) {
      const queryParams = new URLSearchParams(window.location.search);
      let token = queryParams.get("t");
      let refreshToken = queryParams.get("rt");
      let isAdmin = queryParams.get("admin");
      if (token) {
        localStorage.setItem("jwtToken", token);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("isAdmin", isAdmin);
      }
    }

    let token = localStorage.getItem("jwtToken");
    if (!token) {
      navigate("/");
      setLoading(false);
      return;
    }

    try {
      authService.getUser().then((res) => {
        if (res?.data) {
          if (res.data?.id) localStorage.setItem("updatedTenant", res.data.id);

          let data = JSON.stringify(res?.data);
          localStorage.setItem("BoosstLogin", data);
          onLoginStatusChange(JSON.parse(data));
          let redirectqueryParamExist = false;
          if (checkQP) redirectqueryParamExist = checkAndRedirectQueryParams();
          if (!redirectqueryParamExist) {
            if (
              !location.pathname ||
              location.pathname === "" ||
              location.pathname === "/"
            ) {
              navigate("/home/dashboard");
            }
          }
        } else {
          navigate("/");
        }
        setLoading(false);
      });
    } catch (e) {
      setLoading(false);
    }
  };

  const checkAndRedirectQueryParams = () => {
    const queryParams = new URLSearchParams(window.location.search);
    let page = queryParams.get("p");

    if (!page) return false;
    if (page === "d") navigate("/home/dashboard");
    else if (page === "r") navigate("/marketplace");
    return true;
  };

  const initialized = hotjar?.initialized();

  useEffect(() => {
    if (initialized && loginStatus?.id)
      hotjar.identify(loginStatus.email, loginStatus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialized, loginStatus?.id]);

  useEffect(() => {
    getUser(true);

    // in case user token has expired and we have not showed him the alert yet
    if (getAndRemoveLSItem("showAlert")) {
      const isLoginPage =
        window.location.pathname === "" || window.location.pathname === "/";
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Your session has expired. Please sign in again.",
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: true,
        focusCancel: false,
        confirmButtonText: isLoginPage ? "Ok" : "Go To Sign in",
      }).then((result) => {
        if (!isLoginPage) window.open("/", "_self");
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      value={{
        loginStatus,
        setLoginStatus,
        onLoginStatusChange,
        checkUser,
        onSignOut,
        loading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
