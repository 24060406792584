import * as React from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

export default function LoadingScreen() {
  return (
    <Box
      sx={{ width: "100vw", height: "100vh" }}
      display="flex"
      alignItems={"center"}
      justifyContent={"center"}
    >
      <LinearProgress
        sx={{
          width: "250px",
          height: "6px",
          borderRadius: "3px",
        }}
      />
    </Box>
  );
}
