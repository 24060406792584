import axios from "axios";
import config from "../config/config";
import { removeAuthLocalStorage } from "../utils/common";
import { Mutex } from "async-mutex";

const mutex = new Mutex();

const PublicBaseService = axios.create({
  baseURL: config.server_base_url,
});

const BaseService = axios.create({
  baseURL: config.server_base_url,
});

const refreshAccessToken = async () => {
  try {
    const localRefreshToken = localStorage.getItem("refreshToken");
    if (!localRefreshToken) return { error: "Refresh token does not exist" };
    let refreshTokenResponse = await PublicBaseService.get(
      `public/refresh-token?token=${localRefreshToken}`
    );
    if (refreshTokenResponse?.data?.data) {
      const { token, refreshToken } = refreshTokenResponse.data.data;
      localStorage.setItem("jwtToken", token);
      localStorage.setItem("refreshToken", refreshToken);
      return { token };
    } else return { error: "Unable to Refresh token" };
  } catch (e) {
    return { error: e };
  }
};

const showError = () => {
  removeAuthLocalStorage();
  localStorage.setItem("showAlert", true);
  window.open("/", "_self");
};

BaseService.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("jwtToken");
  config.headers["x-access-token"] = token ? `Bearer ${token}` : "";
  return config;
});

BaseService.interceptors.response.use(
  function (res) {
    return res;
  },
  async function (error) {
    const originalRequest = error.config;
    if (
      (error.response.status === 401 || error.response.status === 403) &&
      !originalRequest._retry
    ) {
      if (!mutex.isLocked()) {
        const release = await mutex.acquire();

        originalRequest._retry = true;
        const { token, error } = await refreshAccessToken();
        if (token) {
          release();
          BaseService.defaults.headers["x-access-token"] = "Bearer " + token;
          return BaseService(originalRequest);
        } else {
          showError(error);
        }
      } else {
        await mutex.waitForUnlock();
        const token = localStorage.getItem("jwtToken");
        BaseService.defaults.headers["x-access-token"] = "Bearer " + token;
        return BaseService(originalRequest);
      }
    }
    return Promise.reject(error);
  }
);

export { BaseService };
